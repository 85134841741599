document.addEventListener("DOMContentLoaded", function (event) {
  //Слайдер новостей
  const newsSlider = document.querySelector(".news-slider");
  const newsSliderContainer = newsSlider.querySelector(".swiper-container");
  const newsSliderScroll = newsSlider.querySelector(".swiper-scrollbar");
  const newsSliderInit = new Swiper(newsSliderContainer, {
    // Optional parameters
    loop: false,
    autoHeight: true,
    breakpoints: {
      // >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 5,
      },
      // >= 640px
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      // >= 1000px
      1000: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    scrollbar: {
      el: newsSliderScroll,
      draggable: true,
    },
  });

  //Слайдер объявлений
  if (window.innerWidth < 639.98) {
    const notificationsSlider = document.querySelector(".notifications__slider");
    const notificationsSliderContainer = notificationsSlider.querySelector(".swiper-container");
    const notificationsSliderScroll = notificationsSlider.querySelector(".swiper-scrollbar");
    const notificationsSliderInit = new Swiper(notificationsSliderContainer, {
      // Optional parameters
      loop: false,
      autoHeight: true,
      scrollbar: {
        el: notificationsSliderScroll,
        draggable: true,
      },
    });
  }

  //Исправления нажатия на ссылку новости на мобильных устройствах
  document.querySelectorAll(".news-card__more").forEach((element) => {
    element.addEventListener("touchstart", (e) => {
      location.href = e.target.getAttribute("href");
    });
  });
});
